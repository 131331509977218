* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

body {
  font-family: "Mulish", sans-serif;
}


.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiInputBase-input-MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-input-MuiOutlinedInput-input,
.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.-MuiTypography-root {
  font-family: "Mulish", sans-serif;
}

.MuiSvgIcon-root.MuiSelect-iconOutlined {
  color: rgb(0, 152, 206) !important;
  
}

/* ********** Pagination Table *********** */

/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: rgb(0, 152, 206);
  } */



.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.MuiTableCell-root {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  flex: 1 1 auto;
  font-family: "Poppins", sans-serif;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.MuiTableCell-root {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Mulish", sans-serif;
}

.MuiButton-root.tableBtn {
  color: rgb(0, 168, 215);
  border-color: rgb(0, 168, 215);
  font-family: "Mulish", sans-serif;
}

.MuiInputBase-input-MuiOutlinedInput-input{
  height: 13px !important;
}


.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd{
  height: 13px !important;
}

.MuiFormLabel-root {
  line-height: 13px !important;
}

/* ***************** Sidebar **************** */

/* .sidebarListItem .css-bshv44-MuiButtonBase-root-MuiListItem-root {
  padding: 0;
} */

.sidebarContainer .MuiList-root {
  display: flex;
  flex-direction: column;
  padding: 3%;
}



.sidebarListItem:hover {
  cursor: pointer;
}

.sidebarItemText .MuiTypography-root {
    color: rgb(0, 168, 215);
  }

  .sidebarIcon
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root {
    color: rgb(0, 168, 215);
  }


/* Active state styling for the text within the list items */
.sidebarListItem.active .sidebarItemText .MuiTypography-root {
  color: white;
}
 

/* Active state styling for the icons within the list items */
.sidebarListItem.active
  .sidebarIcon
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root {
  color: white;
}

.sidebarItemText .MuiTypography-root {
  font-size: 16px ;
  font-weight: 500;
  color: rgb(105, 105, 105);
  font-family: "Mulish", sans-serif;
}

.sidebarIcon
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root {
  color: rgb(0, 168, 215);
}

.MuiListItemIcon-root.sidebarIcon.MuiListItemIcon-root {
  min-width: 48px;
}

/* .sidebarprofieInfo {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap:10px;
  width: 100%;
 
} */

/* .sidebarprofieImg {
  width: 100px;
  height: 100px;
} */

.sidebarAdminName .MuiTypography-root {
  font-size: 20px;
  font-weight: 600;
  font-family: "Mulish", sans-serif;
}
.sidebarListItem.active {
  background-color: rgb(0, 168, 215);
  border-radius: 0.5rem;
  width: calc(100% - 1px); 
  margin: 0 auto; 
}




/* .sidebarListItem.active {
  color: rgb(0, 168, 215);
} */

/* ************* Auth CSS **************** */


.authContainer {
  /* border: 2px solid blue; */
  width: 70%;
  padding: 5%;
  box-shadow: 0 0 10px 0 #d9d9d9;
  background-color: #e5f6fb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.authHeading {
  /* border: 2px solid rgb(0, 255, 204); */
  text-align: center;
  /* Add your typography styles here */
}

.authHeadingBox {
  /* border: 2px solid rgb(255, 238, 0); */
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}

.authContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.authFields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.1rem;

}

.checkboxField {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  
}

.rememberMeField {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 1rem;
}

.rememberMe {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  color: rgb(0, 168, 215);
  border: 1px solid rgba(239, 239, 244, 1);
}

.forgotPasswordLabel {
  /* Add your typography styles here */
  color: rgb(0, 168, 215);
  cursor: pointer;
}

.otpInputContainer {
  border: "1px solid blue";
  display: "flex";
  align-items: "center";
  justify-content: "space-between";
  gap: "2%";
}

.otpInput {
  height: "70px";
  width: "12%";
  border-radius: "10px";
  font-size: "30px";
  text-align: "center";
  border: "1px solid rgba(225, 225, 225, 1)";
}

/* ***** Category ***** ProgramListing ***** TopicListing ***** DietaryListing ***** CuisineListing ***** DietType ***** RecipeListing ***** 
Workout Management *****  ForumListing ***** */

.category-container {
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5px;
}


.button-container {
  display: flex;
  justify-content: end;
  
}

.button-sub-container {

  padding: 10px;

}


.row-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

.row-button {
  color: rgb(0, 168, 215);
  border-color: rgb(0, 168, 215);
}

/* ***** AddCategory ***** EditCategory ***** AddProgram ***** EditProgram ***** AddDietary ***** EditDietary ***** AddCuision ***** EditCuision 
***** AddDietType ***** EditDietType ***** Workout(All) *****  ViewProgram ***** ViewExerciseDetails ***** */

.view-user-container {
  margin: auto;
  padding: 20px;
  /* background-color: rgba(255, 255, 255, 0.8); Slight transparency to see the background image */
  border-radius: 8px;
  position: relative;
  overflow: auto; /* Enable scrolling for overflow content */
  /* height: 100vh; Set height to 100% of viewport height */
}

.view-user-content {
  position: relative;
  z-index: 1;
}

.profile-info {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  margin-bottom: 20px;
}

.viewProfileImg { 
  width: inherit;
  height: inherit;
  border-radius: 1rem;
}

.profile-info-inner {
  position: relative;
  z-index: 1;
}

.profile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6; /* Make the background image slightly transparent */
  object-fit: contain;
  z-index: 0;
}

.profile-details {
  /* background-color: rgba(255, 255, 255, 0.8); Background color for details */
  padding: 20px;
  /* border-radius: 8px; */
  position: relative;
  z-index: 1;
}
.profile-mindetails{
  display: flex;
  flex-direction: column;
}

.albums-list,
.top-tracks-list {
  /* background-color: black; Set background color to black */
  margin: 16px;
  background-color: #e5f6fb;
  border-radius: 4px;
  /* box-shadow: (based on theme.shadows[1]) */
  padding: 24px;
}

.albums-list-inner,
.top-tracks-list-inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.albums-list-content, .top-tracks-list-content {
  margin-top: 10px;
}

.album-item,
.track-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.album-image,
.track-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.album-name,
.track-name {
  margin-top: 10px;
  color: white; /* Set text color to white for contrast */
}


/* ***** PrivacyPolicy ***** EditPrivacyPolicy ***** AboutUs ***** EditAboutUs ***** T&C ***** EditT&C ***** FAQs ***** AddFAQ ***** EditFAQ ***** BannerManagement ***** 
AddBanner ***** EditBanner ***** */

.StaticScreenContainer {
  /* border: 2px solid blue; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.StaticScreenTitle
  .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
  font-weight: 600;
  font-size: 24px !important;
  color: #080515;
  font-family: "Mulish", sans-serif;
  @media (maxwidth: 767px) {
    fontsize: 18px !important;
  }
}

.StaticScreenContent .MuiTypography-root {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #080515 !important;
  font-family: "Mulish", sans-serif !important;
  @media (maxwidth: 767px) {
    fontsize: 8px !important;
  }
}

.StaticEditorContainer {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.StaticBtnContainer {
  /* border: 2px solid red; */
  padding: 0 25%;
  display: flex;
  gap: 2%;
}

.ck-editor__editable_inline {
  min-height: 300px; /* Adjust this value as needed */
}

.StaticScreenbtn {
  /* border: 2px solid red; */
  padding-left: 80%;
}

.StaticScreenContent {
  /* border: 2px solid skyblue; */
  display: Flex;
  flex-direction: column;
  gap: 20px;
}

/* ******* Edit Account */

.profile-img {
  width: 187px;
  height: 187px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profile-img-upload-label {
  display: inline-block;
  position: relative;
}

.profile-img-upload-label input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.rememberMeField {
  display: flex;
  align-items: center;
}

.rememberMe {
  margin-right: 3px; /* Adjust the value as needed */
}
.checkboxField{
  margin-top: 3px;
}
.no-data-found{
 
  display: flex;
  /* align-items: center; */
  justify-content: center;
  
}

.row-button-container{
  gap: 0.5rem;
}


